<template>
  <el-dialog
    title="添加空间"
    :visible.sync="showDialog"
    width="700px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <el-form
      :model="form"
      ref="ruleForm"
      status-icon
      :rules="rules"
      label-width="140px"
      class="form_box"
    >
      <el-form-item label="空间名称：" prop="name">
        <div class="flex-start">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入空间名称"
            clearable
            maxlength="64"
            show-word-limit
            class="inputWidth"
          />
          <!-- <span class="font12 des"
            >(大小写字母，数字和下划线的组合，不超过64个字符)</span
          > -->
        </div>
      </el-form-item>
      <el-form-item label="自我管理框架：" prop="isFrameSelfManaged">
        <el-radio-group v-model="spaceIsFrame">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="自我管理授权：" prop="isAuthorizationSelfManaged">
        <el-radio-group v-model="spaceIsAuthor">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'addSpace',
  props: {
    showDialog: false,
  },
  data() {
    // let checkSpceName = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入空间名称'))
    //   } else {
    // let reg = /^[a-zA-Z_0-9]+$/
    // if (!reg.test(value)) {
    //   callback(new Error('空间名称不符合规范'))
    // }
    // callback()
    //   }
    // }
    return {
      rules: {
        name: [{ required: true, message: '请输入空间名称', trigger: 'blur' }],
        // validator: checkSpceName,
      },
      form: {
        isFrameSelfManaged: null,
        isAuthorizationSelfManaged: null,
        name: '',
      },
      spaceIsFrame: '0',
      spaceIsAuthor: '0',
    }
  },
  mounted() {},
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.spaceIsFrame = '0'
        this.spaceIsAuthor = '0'
      })
    },
    sureFn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.isFrameSelfManaged = Number(this.spaceIsFrame)
          this.form.isAuthorizationSelfManaged = Number(this.spaceIsAuthor)
          this.$emit('sureDialog', this.form)
        }
      })
    },
    closeFn() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.form_box {
  ::v-deep {
    .el-form-item__label {
      padding-right: 30px;
    }
  }
}
.inputWidth {
  width: 350px;
  margin-right: 15px;
}
.des {
  line-height: 14px;
  width: 180px;
}
.selectAdminsBox {
  margin-top: 10px;
  span {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 8px 10px;
    margin: 6px;
    &:first-child {
      margin-left: 0;
    }
  }
  .close {
    margin-left: 5px;
  }
}
</style>
